 /* eslint-disable */

(function() {
	'use strict';
	const NEW_ADMIN_TOKEN = 'admin-auth'

	function Auth($window, $interval, $http, Restangular, Configuration, localStorageService, PusherService) {
		var updating;
		var currentUser = null;
		let isAuthenticated = !!getTokenFromLS();

		function getTokenFromLS () {
			const newAdminToken = angular.fromJson($window.localStorage.getItem(NEW_ADMIN_TOKEN))
			console.log('🚀 ~ getTokenFromLS ~ getTokenFromLS:', newAdminToken)
			if (newAdminToken) {
				return newAdminToken
			}
			return localStorageService.get('Authorization')
		}
		function setTokenToLs (value) { // to mitigate issue of fetching non prefixed keys
			console.log('🚀 ~ setTokenToLs ~ setTokenToLs:', value)
			return $window.localStorage.setItem(NEW_ADMIN_TOKEN, angular.toJson(value))
		}
		// fetch current user from local storage
		var me = function() {
			if (currentUser === null && localStorageService.get('User')) {
				currentUser = localStorageService.get('User');
				_reload_token_on_page_reload(getTokenFromLS());
			}
			return currentUser;
		};

		var isSignedIn = function() {
			return isAuthenticated;
		};

		// declare authToken + update on reload
    var authToken = (function() { // init
      var token = getTokenFromLS()
      if (angular.isString(token)) {
        _startUpdatingToken(token);
      }

      return token;
    })();

		var getToken = function() {
			return authToken;
		};

    var getRedirectUnlockURL = function() {
      // Get the current URL
      const currentUrl = window.location.href

      // Split the URL by '/' and remove the last segment if it exists
      const urlParts = currentUrl.split('/')
      urlParts.pop()

      // Add 'unlock' to the end and join back together
      urlParts.push('sign_in')

      // Create the new URL
      const newUrl = urlParts.join('/')

      return newUrl
    }

		var signIn = function(user) {
      var resource = Restangular.all('admin')
        .all('login')
        .customPOST(
          user,
          undefined,
          undefined,
          { 'Cdbl-Unlock-Account-Url': getRedirectUnlockURL() }
        );

      resource.then(function(restangularResponse) {
        console.log('[signIn] response', restangularResponse);

        const authToken = restangularResponse.headers(Configuration.auth.tokenHeaderKey);
        const user = restangularResponse.user ? restangularResponse.user : restangularResponse;

        if (authToken) {
          isAuthenticated = true;
          localStorageService.add('User', user);
          _startUpdatingToken(authToken);
        }
      });

      return resource;
    }

    var signOut = function() {
      if (angular.isDefined(updating)) {
        $interval.cancel(updating);

        updating = undefined;
        currentUser = null;
        isAuthenticated = false;

        _destroyToken();
      }
    };

		function _reload_token_on_page_reload(token) {
			const payload = {
				authToken: token
			}
			$http.post(`${Configuration.apiUrl}/admin/auth_tokens`, payload)
				.then(response => {
					const authToken = response.headers(Configuration.auth.tokenHeaderKey)
					_updateToken(authToken)
				})
				.catch(function(e) {
					signOut()
				})

			// Restangular.all('admin').all('auth_tokens').post({'authToken': token}).then(function(response) { // Restangular returns promises
			// 		const authToken = response.headers(Configuration.auth.tokenHeaderKey)
			// 		_updateToken(authToken);
			// 	}).catch(function(e) {
			// 		signOut();
			// 	});
		}

		// updates the token in interval
		function _startUpdatingToken(token) {
			if (angular.isDefined(updating)) { // should not happen
				return;
			}

      PusherService.init(token)

      _updateToken(token);

			updating = $interval(function() {
				const payload = {
					authToken: token
				}
				$http.post(`${Configuration.apiUrl}/admin/auth_tokens`, payload)
				.then(response => {
					const authToken = response.headers(Configuration.auth.tokenHeaderKey)
					_updateToken(authToken)
				})
				.catch(function(e) {
					signOut()
				})

				// Restangular.all('admin').all('auth_tokens').post({'authToken': token}).then(function(response) { // Restangular returns promises
				// 	const authToken = response.headers(Configuration.auth.tokenHeaderKey)
				// 	_updateToken(authToken);
				// }).catch(function() {
				// 	signOut();
				// });
			}, Configuration.settings.tokenRenewalInterval * 1000);
		}

		function _updateToken(token) {
			authToken = token; // local var
			setTokenToLs(token); //localStorageService.add('Authorization', token); // storage for refreshing
      PusherService.updateAccessToken(token)
		}

    function _destroyToken() {
			$http.delete(`${Configuration.apiUrl}/admin/login`)
				.then(response => {
					console.log('DELETE TOKEN FROM BE')
				})
				.finally(() => {
					authToken = null;
					$window.localStorage.removeItem(NEW_ADMIN_TOKEN)
      		localStorageService.clearAll();
				})
    }

		return {
			me: me,
			signIn: signIn,
			signOut: signOut,
			getToken: getToken,
			isSignedIn: isSignedIn,
			updateToken: _updateToken
		};
	}

  console.log('[authSrv.js] > window.app', window.app)
	window.app.service('Auth', ['$window', '$interval', '$http', 'Restangular', 'Configuration', 'localStorageService', 'PusherService', Auth]);

})();
